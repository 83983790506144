<template>
  <div class="container">
    <page-block
      title="Укажите, куда доставить заказ"
      subtitle="И рассчитайте срок доставки"
      align="center"
      :page-body="true"
      :small-top="true"
    >
      <map-item />
    </page-block>
  </div>
</template>

<script>
import PageBlock from "@/components/PageBlock";
import MapItem from "@/pages/Map/components/MapItem";

export default {
  name: "DeliveryMap",
  components: {
    MapItem,
    PageBlock,
  },
  computed: {
    dateNow() {
      return this.$store.getters.date;
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.container {
  //margin-top: 50px;
  //margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
}
</style>
