<template>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-5 col-lg-4 order-md-1 mb-3 mb-0">
        <div class="time-box">
          <div class="text-line mb-3">
            <strong> Расчет времени доставки </strong>
          </div>
          <div>
            <input
              class="text-value input-city w-100 text-white"
              placeholder="Введите название города..."
              v-model="city.name"
              @focusin="city.show = true"
              v-click-outside="hideCitySelect"
              @input="searchCity()"
            />
            <div class="position-relative">
              <div class="select-city" v-if="city.show">
                <div class="empty" v-if="city.name.length == 0">
                  Введите название города для поиска
                </div>
                <div
                  class="empty"
                  v-if="
                    city.list.length == 0 &&
                    city.name.length != 0 &&
                    !city.searching
                  "
                >
                  Ничего не найдено
                </div>
                <div
                  class="empty"
                  v-if="city.searching && city.name.length != 0"
                >
                  Поиск...
                </div>
                <div
                  class="item"
                  v-for="item in city.list"
                  :key="item.value"
                  @click="selectCity(item)"
                >
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
          <div class="text-value mt-3" v-if="!point && !pointLoad">
            Выберите пункт выдачи заказа
          </div>
          <div
            class="text-value mt-3"
            :class="{ success: point.avgTime > 0 }"
            v-if="point && !pointLoad && pointInfo"
          >
            <span v-if="point.avgTime > 0"
              >Доставим за <b>{{ averageDays(point.avgTime) }}</b></span
            >
            <span v-else
              >К сожалению мы не смогли получить сроки доставки 😔<br />Попробуйте
              выбрать другой пункт</span
            >
          </div>
          <div class="text-value mt-3" v-if="pointLoad">Рассчитываю...</div>
          <!--          <div class="d-flex justify-content-center mt-3" v-if="pointLoad">
            <svg
              style="max-width: 100px"
              version="1.1"
              id="L9"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve"
            >
              <path
                fill="#fff"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite"
                ></animateTransform>
              </path>
            </svg>
          </div>-->
          <!--          <div class="mt-3">
            <div v-if="pointInfo.office">
              <div>
                <strong>Адрес:</strong>
                {{ pointInfo.office.address.fullAddress }}
              </div>
            </div>
            <div v-if="pointInfo.terminal">
              <div>
                <strong>Адрес:</strong>
                {{ pointInfo.terminal.city_abbr }}
                {{ pointInfo.terminal.city_name }},
                {{ pointInfo.terminal.street_abbr }}
                {{ pointInfo.terminal.street }}
                {{ pointInfo.terminal.house_no }}
              </div>
              <div class="mt-1">
                <strong>Дополнительная информация:</strong>
                {{ pointInfo.terminal.descript }}
              </div>
            </div>
          </div>-->
          <!--          <div class="mt-3" v-if="pointInfo">
            <button class="btn-scroll" @click="selectPoint">
              Оформить заказ на данный пункт выдачи
            </button>
          </div>-->
          <!--          <div class="mt-3 small" v-if="point && point.avgTime > 0">
            <i class="text-muted">Показано среднее время на доставку</i>
          </div>-->
          <div class="mt-3 d-none d-md-block">
            <p>
              Заказ доставляется курьерскими компаниями или Почтой РФ с опцией
              «проверка перед оплатой». Это 100% гарантия соответствия товара,
              т.к можно вскрыть посылку и всё проверить ДО оплаты (и на Почте
              тоже).
            </p>
            <p>
              При доставке в удалённые города (особенно с авиадоставкой: ХМАО,
              ЯНАО, Камчатка, Чукотка и т.п.) и ПГТ возможна доп. комиссия.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7 col-lg-8 order-md-0">
        <div class="map-box" id="map-box"></div>
      </div>
    </div>
    <div class="mt-3 d-md-none time-box">
      <p>
        Заказ доставляется курьерскими компаниями или Почтой РФ с опцией
        «проверка перед оплатой». Это 100% гарантия соответствия товара, т.к
        можно вскрыть посылку и всё проверить ДО оплаты (и на Почте тоже).
      </p>
      <p>
        При доставке в удалённые города (особенно с авиадоставкой: ХМАО, ЯНАО,
        Камчатка, Чукотка и т.п.) и ПГТ возможна доп. комиссия. При
        <a
          href="https://pay.domspirt.ru/"
          class="text-primary text-decoration-underline"
          >оплате на сайте</a
        >
        комиссии нет.
      </p>
    </div>
  </div>
</template>

<script>
import { DELIVERY } from "@/store/modules/tracking";
import ymaps from "ymaps";
import axios from "axios";
import { GET_COORDINATES, SET_DELIVERY_POINT } from "@/store/modules/order";
// import ymaps from "@/modules/ym"

let cancel;
let CancelToken = axios.CancelToken;

let cancelCity;
let CancelTokenCity = axios.CancelToken;

let myMap;

export default {
  name: "MapItem",
  components: {},
  data: () => {
    return {
      settings: {},
      markers: [],
      map: null,
      objectManager: null,
      ym: null,

      count: 0,
      coords: [],

      searching: false,
      searchingTimeout: null,

      point: false,
      pointInfo: false,
      pointLoad: false,

      city: {
        name: "",
        show: false,
        list: [],
        searching: false,
        searchingTimeout: null,
      },

      mapInit: false,
    };
  },
  computed: {
    dpdMarkers() {
      return this.markers.filter((v) => v.type === "dpd");
    },
    rpMarkers() {
      return this.markers.filter((v) => v.type === "rp");
    },
  },
  methods: {
    boundsChange(e) {
      // this.markers = [];
      this.$store.dispatch(DELIVERY, e.originalEvent.newBounds).then((r) => {
        this.markers = [];
        this.$nextTick(() => {
          this.markers = r.marks;
        });
      });
    },
    createdYandexMap(e) {
      console.log(e);
    },
    averageDays(v) {
      let d = v > 0 ? Math.ceil(v / 86400) : 0;
      return d > 0 ? d + " " + this.getNoun(d, "день", "дня", "дней") : "";
    },
    loadData(myMap, objectManager) {
      let self = this;
      clearTimeout(this.searchingTimeout);
      this.searchingTimeout = null;
      this.searching = true;
      this.searchingTimeout = setTimeout(() => {
        if (cancel != undefined) {
          cancel();
          // console.log("cancelled");
        }
        this.coords = myMap.getBounds();

        let topLeft = [this.coords[1][0], this.coords[0][1]];
        let bottomRight = [this.coords[0][0], this.coords[1][1]];

        axios({
          method: "post",
          url: "/get.delivery",
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
          data: {
            topLeft,
            bottomRight,
          },
        }).then((response) => {
          let r = response.data;
          this.count = r.marks.length;

          let BalloonContentLayout = this.ym.templateLayoutFactory.createClass(
            '<div class="ya-ballon" style="margin: 10px; display: {{properties.display}};">' +
              '<h5 class="fw-bold">{{properties.balloonContentHeader}}</h5>' +
              '<div class="text-muted">{{properties.balloonContentFooter}}</div>' +
              '<div class="mb-2">{{properties.balloonContentBody}}</div>' +
              '<button id="start-order-button" class="btn btn-primary"> Оформить заказ на данный пункт выдачи</button>' +
              '</div><div style="display: {{properties.loading}};">Загрузка...</div>',
            {
              // Переопределяем функцию build, чтобы при создании макета начинать
              // слушать событие click на кнопке-счетчике.
              build: function () {
                // Сначала вызываем метод build родительского класса.
                BalloonContentLayout.superclass.build.call(this);
                // А затем выполняем дополнительные действия.
                document.getElementById("start-order-button").onclick =
                  self.selectPoint;
              },

              // Аналогично переопределяем функцию clear, чтобы снять
              // прослушивание клика при удалении макета с карты.
              clear: function () {
                // Выполняем действия в обратном порядке - сначала снимаем слушателя,
                // а потом вызываем метод clear родительского класса.
                document.getElementById("start-order-button").onclick = null;
                BalloonContentLayout.superclass.clear.call(this);
              },

              // onCounterClick: function () {
              //   document.getElementById("count").innerHTML = ++counter;
              //   if (counter == 5) {
              //     alert("Вы славно потрудились.");
              //     document.getElementById("count").innerHTML = counter;
              //   }
              // },
            }
          );

          let array = r.marks.map((v) => ({
            id: v.id,
            type: "Feature",
            // data_type: v.type,
            geometry: {
              type: "Point",
              coordinates: [parseFloat(v.latitude), parseFloat(v.longitude)],
            },
            properties: {
              // iconCaption: v.name,
              // balloonContentHeader: v.name,
              // balloonContentBody:
              //   "Среднее время доставки: " + this.averageDays(v.avgTime),
              // clusterCaption: "<strong>" + v.type + "</strong> (" + v.id + ")",
              // hintContent: "<strong>" + v.type + "</strong>",
              balloonContent: "Загрузка...",
              clusterCaption: v.name,
              display: "none",
              loading: "block",
              index: v.type == "rp" ? v.postalCode : null,
              terminal: v.type == "dpd" ? v.terminalCode : null,
              type: v.type,
              info: {
                terminal: v.terminal,
                office: v.office,
              },
            },
            options: {
              iconColor: v.color,
              preset: "islands#blueCircleIcon",
              balloonContentLayout: BalloonContentLayout,
              balloonPanelMaxMapArea: 0,
            },
          }));
          objectManager.add({
            type: "FeatureCollection",
            features: array,
          });

          // objectManager.objects.events.add(["click"], function (e) {
          //   let item = objectManager.objects.getById(e.get("objectId"));
          //   if (item.properties.type === "dpd") {
          //     self.loadPoint(
          //       item.properties.type,
          //       item.properties.terminal,
          //       item.properties.info
          //     );
          //   } else if (item.properties.type === "rp") {
          //     self.loadPoint(
          //       item.properties.type,
          //       item.properties.index,
          //       item.properties.info
          //     );
          //   }
          // });
          // myMap.geoObjects
          //     .add(
          //         new this.ym.Placemark(
          //             topLeft,
          //             {
          //                 balloonContent: JSON.stringify(topLeft),
          //             },
          //             {
          //                 preset: "islands#circleIcon",
          //                 iconColor: "#3caa3c",
          //             }
          //         )
          //     )
          //     .add(
          //         new this.ym.Placemark(
          //             bottomRight,
          //             {
          //                 balloonContent: JSON.stringify(bottomRight),
          //             },
          //             {
          //                 preset: "islands#circleIcon",
          //                 iconColor: "#3caa3c",
          //             }
          //         )
          //     );
          this.searching = false;
          // this.markers = [];
          // this.$nextTick(() => {
          //     this.markers = r.marks;
          // });
        });
      }, 500);
    },
    downloadContent(geoObjects, id, objectManager, isCluster) {
      let self = this;

      // Создадим массив меток, для которых данные ещё не загружены.
      let array = geoObjects.filter(function (geoObject) {
        return (
          geoObject.properties.balloonContent === "Загрузка..." ||
          geoObject.properties.balloonContent === "Not found"
        );
      });
      // Формируем массив идентификаторов, который будет передан серверу.
      // ids = array.map(function (geoObject) {
      //   console.log(geoObject);
      //   return geoObject.id;
      // });
      let item = array[0];
      if (item) {
        // Запрос к серверу.
        // Сервер обработает массив идентификаторов и на его основе
        // вернет JSON-объект, содержащий текст балуна для
        // заданных меток.

        let vl = null;
        let address = "...";
        let about = "Пункт выдачи заказов интернет-магазинов";
        if (item.properties.type === "dpd") {
          vl = item.properties.terminal;
          address =
            item.properties.info.terminal.city_abbr +
            " " +
            item.properties.info.terminal.city_name +
            ", " +
            item.properties.info.terminal.street_abbr +
            " " +
            item.properties.info.terminal.street +
            " " +
            item.properties.info.terminal.house_no;
          //   about = "Пункт выдачи DPD";
        } else if (item.properties.type === "rp") {
          vl = item.properties.index;
          address = item.properties.info.office.address.fullAddress;
          //   about = "Отделение Почты России";
        }

        this.loadPoint(item.properties.type, vl, item.properties.info).then(
          (data) => {
            // console.log(data);
            geoObjects.forEach((geoObject) => {
              geoObject.properties.balloonContentBody = address;
              geoObject.properties.balloonContentFooter = about;
              geoObject.properties.hintContent = about;
              geoObject.properties.display = "block";
              geoObject.properties.loading = "none";
              geoObject.properties.balloonContentHeader =
                "Доставим за " + self.averageDays(data.avgTime);
            });
            // Оповещаем балун, что нужно применить новые данные.
            setNewData();
          },
          function () {
            geoObjects.forEach(function (geoObject) {
              geoObject.properties.balloonContent = "Not found";
            });
            // Оповещаем балун, что нужно применить новые данные.
            setNewData();
          }
        );
      }

      function setNewData() {
        if (isCluster && objectManager.clusters.balloon.isOpen(id)) {
          objectManager.clusters.balloon.setData(
            objectManager.clusters.balloon.getData()
          );
        } else if (objectManager.objects.balloon.isOpen(id)) {
          objectManager.objects.balloon.setData(
            objectManager.objects.balloon.getData()
          );
        }
      }
    },
    searchCity() {
      let self = this;
      clearTimeout(this.city.searchingTimeout);
      this.city.searchingTimeout = null;
      this.city.searching = true;
      this.city.list = [];
      this.city.searchingTimeout = setTimeout(() => {
        if (cancelCity != undefined) {
          cancelCity();
          // console.log("cancelled");
        }

        axios({
          method: "post",
          url: "/get.city",
          cancelToken: new CancelTokenCity(function executor(c) {
            cancelCity = c;
          }),
          data: {
            name: self.city.name,
          },
        }).then((response) => {
          this.city.list = response.data;
          this.city.searching = false;
        });
      }, 500);
    },
    loadMap() {
      let self = this;
      // let geolocation = this.ym.geolocation;

      myMap = new this.ym.Map(
        "map-box",
        {
          center: [55.755864, 37.617698],
          zoom: 13,
        },
        {
          searchControlProvider: "yandex#search",
        }
      );
      myMap.controls.remove("searchControl");
      myMap.controls.remove("trafficControl");
      myMap.controls.remove("typeSelector");
      myMap.controls.remove("rulerControl");
      myMap.controls.remove("routeEditor");
      myMap.controls.remove("fullscreenControl");
      // geolocation
      //   .get({
      //     mapStateAutoApply: true,
      //     provider: "yandex",
      //   })
      //   .then(function (result) {
      //     console.log(result);
      //     myMap.geoObjects.add(result.geoObjects);
      //   });
      let objectManager = new this.ym.ObjectManager({
        // Чтобы метки начали кластеризоваться, выставляем опцию.
        clusterize: true,
        // ObjectManager принимает те же опции, что и кластеризатор.
        gridSize: 32,
        // clusterDisableClickZoom: true,
        clusterIconLayout: "default#pieChart",

        clusterHideIconOnBalloonOpen: false,
        // geoObjectHideIconOnBalloonOpen: false,
        // clusterDisableClickZoom: true,
      });

      // Чтобы задать опции одиночным объектам и кластерам,
      // обратимся к дочерним коллекциям ObjectManager.
      // objectManager.objects.options.set("preset", "islands#greenDotIcon");
      // objectManager.clusters.options.set(
      //     "preset",
      //     "islands#greenClusterIcons"
      // );
      myMap.geoObjects.add(objectManager);

      myMap.events.add("boundschange", function () {
        self.loadData(myMap, objectManager);
      });

      objectManager.objects.events.add("balloonopen", function (e) {
        // Получим объект, на котором открылся балун.
        var id = e.get("objectId"),
          geoObject = objectManager.objects.getById(id);
        // Загрузим данные для объекта при необходимости.
        self.downloadContent([geoObject], id, objectManager);
      });

      objectManager.clusters.events.add("balloonopen", function (e) {
        // Получим id кластера, на котором открылся балун.
        var id = e.get("objectId"),
          // Получим геообъекты внутри кластера.
          cluster = objectManager.clusters.getById(id),
          geoObjects = cluster.properties.geoObjects;

        // Загрузим данные для объектов при необходимости.
        self.downloadContent(geoObjects, id, objectManager, true);
      });

      // myMap.events.add("click", function (e) {
      //     console.log(e);
      //     if (!myMap.balloon.isOpen()) {
      //         var coords = e.get("coords");
      //         myMap.balloon.open(coords, {
      //             contentHeader: "Событие!",
      //             contentBody:
      //                 "<p>Кто-то щелкнул по карте.</p>" +
      //                 "<p>Координаты щелчка: " +
      //                 [
      //                     coords[0].toPrecision(6),
      //                     coords[1].toPrecision(6),
      //                 ].join(", ") +
      //                 "</p>",
      //             contentFooter: "<sup>Щелкните еще раз</sup>",
      //         });
      //     } else {
      //         myMap.balloon.close();
      //     }
      // });

      self.loadData(myMap, objectManager);
    },
    loadPoint(type, value, info) {
      let self = this;
      return new Promise((resolve) => {
        // if (self.pointLoad === false) {
        let name;
        if (type === "dpd") {
          name = "terminal";
        } else if (type === "rp") {
          name = "index";
        }

        self.pointLoad = true;
        // self.pointInfo = false;

        axios({
          method: "get",
          url: "/get.delivery?" + name + "=" + value,
        }).then((response) => {
          let r = response.data;
          self.point = r;
          self.pointInfo = info;
          self.pointLoad = false;
          resolve(r);
        });
        // }
      });
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    selectCity(city) {
      myMap.setCenter([city.data.geo_lat, city.data.geo_lon], 13);
      this.hideCitySelect();
      this.pointInfo = false;
      this.city.name = city.value;
      this.$metrika.reachGoal("select-city");
    },
    hideCitySelect() {
      this.city.show = false;
    },
    selectPoint() {
      this.$store.commit(SET_DELIVERY_POINT, this.pointInfo);
      this.$router.push({ name: "main", hash: "#order" });
      this.$metrika.reachGoal("select-point");
    },
    getCoords() {
      this.$store.dispatch(GET_COORDINATES).then((res) => {
        if (res.status === "success") myMap.setCenter([res.lat, res.lon], 13);
      });
    },
  },
  mounted() {
    ymaps
      .load(
        "//api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=6a2ed4c5-5b46-45c6-ad89-c4ac772aecc7"
      )
      .then((maps) => {
        this.mapInit = true;
        this.ym = maps;
        this.loadMap();
        this.getCoords();
      })
      .catch((error) => console.log("Failed to load Yandex Maps", error));
  },
  beforeUnmount() {
    myMap.destroy();
  },
};
</script>

<style lang="scss" scoped>
.map-box {
  background: #fff;
  border-radius: 15px;
  min-height: 500px;
  overflow: hidden;
}

.time-box {
  padding: 15px 20px;
  border-radius: 15px;
  background-color: #404040b5;

  .text-line {
  }
  .text-value {
    border-radius: 10px;
    padding: 15px 20px;
    background-color: #404040b5;
    text-align: center;

    &.success {
      background-color: #036e08b5;
    }
  }

  @media (max-width: 768px) {
    border: 2px solid #2a80f4;
  }
}

.input-city {
  border-radius: 10px;
  box-shadow: none;
  border: none !important;

  &:active,
  &:focus {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #505050;
  }
}

.select-city {
  position: absolute;
  z-index: 9999;
  background: #505050;
  width: 100%;
  padding: 10px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .item {
    padding: 5px 15px;
    &:hover {
      background: #777777;
      cursor: pointer;
    }
  }
  .empty {
    padding: 5px 15px;
    text-align: center;
  }
}

.btn-scroll {
  padding: 12px 20px 11px;
  background: #2a93f4;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  border: none;
  width: 100%;
  color: #fff;
  &[disabled] {
    background: #444444;
  }
}
</style>
